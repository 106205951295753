import React from "react"
import {forwardRef} from "react";
import styled from 'react-emotion';
import MaterialTable from 'material-table';
import moment from 'moment-timezone';
import axios from 'axios';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const tableRef = React.createRef();
window.tableRef = tableRef;
const callHistoryView = {
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
  marginTop: '30px',
  padding: '15px',
  overflow: 'auto',
  height: '100%'
};
const callHistoryViewHeading = {
  // fontWeight: 'bold',
  fontSize: '20px',
  marginBottom: '10px',
  marginLeft: '50px'
};

const callHistoryViewSubHeading = {
  fontWeight: 400,
    fontSize: '16px',
    marginBottom: '6px',
    marginLeft: '50px'
};
const callIcon = {
  cursor: 'pointer',
  marginRight: '5px'
};
const summaryValueSpan= {
  paddingLeft: '30px'
};
const summaryTable = {
  marginLeft: '50px',
  backgroundColor: '#efefef'
};
const zeroLeftMargin = {
  marginLeft: '0px !important'
};
const zeroLeftPadding = {
  paddingLeft: '0px !important'
};
const pageTitle = {
  marginLeft: '-26px'
};
const tabsComponentMobile = {
  width: '40%',
  marginLeft: '0px',
  background: '#efefef',
  boxShadow: 'none'
};
const tabsComponentDesktop = {
  width: '30%',
  marginLeft: '50px',
  background: '#efefef',
  height: '34px',
  boxShadow: 'none'
};
const tableHeading = {
  fontWeight: 400,
    fontSize: '16px',
    marginBottom: '20px',
    marginLeft: '50px',
    marginTop: '30px'
};
const resetFilters = {
  padding: '6px 12px',
  fontSize: '14px',
  color: '#555',
  backgroundColor: '#fff',
  border: '1px solid #ccc',
  marginBottom: '30px',
  marginLeft: '50px',
  cursor: 'pointer'
};
const filtersDivDesktop = {
  display: 'flex'
};
const filtersDivMobile = {
  display: 'flex',
  marginLeft: '-50px'
};
const zeroRightMargin = {
  marginRight: '0px'
};
const datePicker = {
  marginLeft: '10px',
  zIndex: 10
};
const tabRootMobile = {
  borderRight: '1px #adabab solid',
  borderLeft: '1px #adabab solid',
};
const tabRootDesktop = {
  borderRight: '1px #adabab solid',
  borderLeft: '1px #adabab solid',
  minHeight: '30px'
};
const tabSelectedText = {
  fontWeight: 600
};
const tabSelectedIndicator = {
  backgroundColor: '#585757'
};
const modalText = {
  fontSize: '12px',
  color: 'black'
};
const summaryFilter = {
  cursor: 'pointer',
  textDecoration: 'underline'
};
const searchIcon= {
  position: 'relative',
  left: '259px',
  zoom: '1.3',
  backgroundColor: '#1875d2',
  color: 'white'
};

const MainTableContainer = styled('div')`
  overflow: scroll;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 50px;
  > div:first-child{
    min-width: 1000px;
    box-shadow: none;
    > div:first-child{
        min-height: 0;
    }
  }
  table {
    width: 80%;
  }
  tbody>tr>td{
    color: black;
  }
  tbody>tr>td>div>div>div{
    color: black;
  }
`;

const DatePickerDiv = styled('div')`
  margin-left: 40px;
`;

const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  };

class MissedCalls extends React.Component {
  constructor(props) {
    super();
    this.state = {
      rows: [],
      is_call_enabled: '',
    }
  }

  componentDidMount() {
    this.getBasicData();
  }
  smartCallHandling(event){
    let _this = this;
    let to = event.target.dataset.to;
    this.props.device.connect({
      phoneNumber: to
    });
    axios.post('/update_callbacked_missed_calls',{
        call_sid: event.target.dataset.call
    }).then(()=>{
        _this.getCallsData();
    });
  }
  updateCallBack(event){
    let _this = this;
    axios.post('/update_callbacked_missed_calls',{
        call_sid: event.target.dataset.call
    }).then(()=>{
        _this.getCallsData();
        _this.props.numMissedCalls();
    });
  }

  getBasicData(){
    let _this = this;
    let columns = [
      {
        title: 'Datum & Uhrzeit', field: 'callStartTime', filtering: false,
        customSort: (a,b)=>{ return (moment(a.date) - moment(b.date))},
        sorting: true,
        defaultSort: 'desc',
        cellStyle: {textAlign: 'center', width: '175px'},
        headerStyle:{width: '150px'},
        render: rowData => {
          return moment(rowData.date).tz("Europe/Berlin").format('DD.MM.YYYY HH:mm') + " Uhr";
        }
      },
      {
        title: 'Eingehende Nummer', field: 'caller_id', filtering: false,
        cellStyle: {textAlign: 'center'},
        render: rowData => {
            return rowData.caller_id;
        }
      },
      { 
        title: 'Kundennummer', field: 'number', filtering: false,
        cellStyle: {textAlign: 'center'},
        render: rowData => {
            let number = rowData.number
            let check_for_anonymous = ["+266696687", "+17378742833"].includes(number);
            let formatted_number = check_for_anonymous ? 'Anonymer Anrufer' : number
            if(formatted_number && formatted_number.includes('client:')){
              formatted_number = formatted_number.substring(7).split('_40')[0].split('_2E').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
            }
            return(
              <div>
                  {(formatted_number !== 'Anonymer Anrufer') &&
                  <span role="img" onClick={_this.smartCallHandling.bind(_this)} aria-label="Call" data-to={formatted_number} data-call={rowData.call_sid} style={callIcon}>&#128222;</span>}
                  {formatted_number}
              </div>
            )
        }
      },
      {
        title: 'Rückruf erfolgt', field: 'called_back', filtering: false,
        cellStyle: {textAlign: 'center'},
        render: rowData =>{
          let calledBack = "Nein";
          if(rowData.called_back) calledBack = "Ja";
          return (<div>
              {calledBack}
              {(!rowData.called_back)&& <button className="inline-button" data-call={rowData.call_sid} onClick={_this.updateCallBack.bind(_this)}>Zurückgerufen</button>}
          </div>);
        }
      }
    ];
    this.setState({agent_columns: columns});
    this.getCallsData();
  }
  getCallsData(query) {
    return(new Promise((resolve, reject) => {
      let url = `/get_missed_calls`
      fetch(url)
      .then(response => response.json())
      .then(result => {
          console.log(result);
        this.setState({
          rows: result.rows
        });
        resolve({
          data: result.rows
        })
      })
    }));
  }

  render () {
    return (
      <div style={callHistoryView}>
        <h3 style={callHistoryViewHeading}>Verpasste Anrufe</h3>
        <h2 style={tableHeading}>Anrufliste</h2>
        <MainTableContainer>
            <MaterialTable
            columns={this.state.agent_columns}
            tableRef={tableRef}
            data={this.state.rows}
            localization={{ body:{emptyDataSourceMessage: <h3>Sie hatten keine verpassten Anrufe in den letzten sieben Tagen!</h3>}}}
            icons={tableIcons}
            options={{
            // pageSizeOptions: [],
            showTitle: false,
            filtering: true,
            search: false,
            // pageSize: 20,
            // debounceInterval: 700,
            headerStyle: {
              backgroundColor: '#184286',
              color: '#ffffff',
              zIndex: 1,
              textAlign: 'center',
              fontSize: '1em'
            },
            rowStyle: rowData => {
                if(!rowData.called_back) return({height: '40px', backgroundColor: '#f5c8c8'});
                else return({ height: '40px' });
            },
            filterCellStyle: {
                backgroundColor: '#efefef',
                paddingTop: '2px',
                paddingBottom: '2px'
            }
            }}
            />
        </MainTableContainer>
      </div>
    );
  }
}
export default MissedCalls
