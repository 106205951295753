import React from "react";
import { css } from "emotion";
import Button from "@material-ui/core/Button";

const acceptedNumbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

const rippleDTMF = css`
  background-position: center !important;
  transition: background 0.8s !important;
  &:hover {
    background: #614ffb radial-gradient(circle, transparent 1%, #614ffb 1%) center/15000% !important;
    color: black;
  }
  &:active {
    background-color: black !important;
    background-size: 100% !important;
    transition: background 0s !important;
  }
`;

const dtmfKey = css`
  padding: 0 0 !important;
  min-width: 52px !important;
  min-height: 52px;
  border-radius: 38px !important;
  font-size: 18px !important;
  font-weight: bold !important;
`;

const inputContainer = css`
  max-width: 387px;
  margin-bottom: 35px;
  text-align: center;
`;

const buttonsContainer = css`
  align-self: center;
`;

export default class DTMFInput extends React.Component {
  numbers = [
    ["1", "2", "3"],
    ["4", "5", "6"],
    ["7", "8", "9"],
    ["*", "0", "#"]
  ];
  constructor(props) {
    super();
    this.state = {
      screenLine: "",
      plus: ""
    };
  }

  buttonPress(key) {
    let conn = this.props.connection;
    conn.sendDigits(key);
  }

  inputChangedHandler(event) {
    //do nothing this is for warning fix
  }

  render() {
    return (
      <div className={`${inputContainer}`}>
        <div className={`${buttonsContainer}`}>
          {this.numbers.map((row, i) => {
            let originalThis = this;

            return (
              <div key={i}>
                {row.map((item, i) => {
                  return (
                    <Button size="large" className={`${rippleDTMF} ${dtmfKey}`} key={item} onClick={() => originalThis.buttonPress(item)}>
                      {item}
                    </Button>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
