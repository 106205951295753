import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import { DialpadStyles } from "./DialpadStyless";
import DialpadComponent from "./DialpadComponent";
import DTMFInput from "./DTMFInput";

import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import CallEndIcon from "@material-ui/icons/CallEnd";
import CallIcon from "@material-ui/icons/Call";
import DialpadIcon from "@material-ui/icons/Dialpad";
import CloseIcon from "@material-ui/icons/Close";
import CallHistory from "./CallHistory";
import MissedCalls from "./MissedCalls";
import CallDiversion from "./CallDiversion";
import AddressBook from "./AddressBook";

class CallControl extends React.Component {
  constructor(props) {
    super();
    this.state = {
      callStatus: "Connecting to Twilio....",
      callMuted: false,
      callConnected: false,
      callIncoming: false,
      showDTMFInput: false,
      showDialpad: true,
      showDisplay: 1,
      numMissedCalls: 0
    };
    this.initDevice();
  }
  getMissedCallsData() {
    var _this = this;
    new Promise((resolve, reject) => {
      let url = `/get_missed_calls`;
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          let r = result.rows;
          let count = 0;
          for (let i = 0; i < r.length; i++) {
            if (!r[i].called_back) count += 1;
          }
          _this.setState({
            numMissedCalls: count
          });
          resolve({
            data: result.rows
          });
        });
    });
  }

  componentDidMount() {
    var _this = this;
    let timer = setInterval(() => {
      _this.getMissedCallsData();
      clearInterval(timer);
    }, 1000);
  }

  async sendRequest(event, conn) {
    console.log("SENDING REQUEST");
    if (conn.direction === "OUTGOING") {
      await axios.post("/log_call_history", {
        date: Date(),
        inbound_number: conn.message.phoneNumber,
        event_type: event,
        direction: conn.direction,
        call_sid: conn.parameters.CallSid,
        event_time: Date()
      });
    } else {
      await axios.post("/log_call_history", {
        date: Date(),
        inbound_number: conn.parameters.From,
        event_type: event,
        direction: conn.direction,
        call_sid: conn.parameters.CallSid,
        event_time: Date()
      });
    }
  }

  async initDevice() {
    let tokenData = await axios.post(`/token/generate`, {});
    let token = tokenData.data.token;
    let _this = this;

    var device = new Twilio.Device(token, {
      codecPreferences: ["opus", "pcmu"],
      fakeLocalDTMF: true,
      enableRingingState: true
    });

    this.setState({ device: device });

    if ("Notification" in window && navigator.serviceWorker && Notification.permission == "default") {
      Notification.requestPermission(function (status) {
        console.log("Notification permission status:", status);
      });
    }

    device.on("ready", function (device) {
      console.log("-------DEVICE READY---------");
      _this.updateCallStatus("Bereit");
    });

    device.on("error", function (error) {
      console.log("Twilio.Device Error: " + error.message);
      _this.updateCallStatus("ERROR: " + error.message);
      if (error.message === "Connection with Twilio was interrupted.") {
        alert("Ein/e andere/r Mitarbeiter/in in der gleichen Klingelgruppe hat diesen Anruf kurz vor Ihnen angenommen.");
        location.reload();
      }
    });

    device.on("cancel", function (error) {
      console.log("-------CANCELLED---------");
      _this.updateCallStatus("Bereit");
      _this.setState({
        callIncoming: false,
        callConnected: false,
        showDTMFInput: false,
        showDialpad: true
      });
      setTimeout(() => {
        _this.getMissedCallsData();
      }, 1000);
    });

    device.on("connect", function (conn) {
      console.log("-------CONNECTED---------");
      _this.setState({
        connection: conn,
        callConnected: true,
        callIncoming: false,
        showDialpad: false,
        showDisplay: 1
      });

      if ("phoneNumber" in conn.message) {
        _this.updateCallStatus("Im Telefonat mit " + conn.message.phoneNumber);
      }
      _this.sendRequest("call_connected", conn);
      _this.getMissedCallsData();
    });

    device.on("disconnect", function (conn) {
      console.log("-------DISCONNECT---------");
      _this.setState({
        callMuted: false,
        callConnected: false,
        callIncoming: false,
        showDTMFInput: false,
        showDialpad: true,
        showDisplay: 1
      });
      _this.updateCallStatus("Bereit");
      _this.sendRequest("call_ended", conn);
    });

    device.on("incoming", function (conn) {
      console.log("-------INCOMING---------\n", conn);
      _this.setState({ connection: conn });
      _this.updateCallStatus(`Eingehender Anruf von ${conn.parameters.From}`);

      _this.displayNotification(conn);
      conn.on("accept", function () {
        _this.updateCallStatus(`Im Telefonat mit ${conn.parameters.From}`);
      });

      _this.setState({
        callIncoming: true
      });
    });
  }

  updateCallStatus(text) {
    this.setState({ callStatus: text });
  }

  answerCall() {
    let conn = this.state.connection;
    conn.accept();
  }

  rejectCall() {
    let conn = this.state.connection;
    conn.reject();
    this.setState({
      callIncoming: false
    });
    this.updateCallStatus("Bereit");
  }

  hangupCall() {
    var device = this.state.device;
    device.disconnectAll();
  }

  toggleMute() {
    var newMuteValue = !this.state.callMuted;
    this.setState({ callMuted: newMuteValue });
    this.state.connection.mute(newMuteValue);
  }

  toggleDTMFInput() {
    this.setState({ showDTMFInput: !this.state.showDTMFInput });
  }

  displayNotification(connection) {
    // let _this = this;
    if (Notification.permission == "granted") {
      var notif = new Notification("Incoming Call", {
        body: connection?.parameters?.From ? `Call from ${connection.parameters.From}` : "Incoming Call"
      });
      notif.onclick = function () {
        parent.focus();
        window.focus();
        this.close();
      };
    }
  }

  showCallCenter() {
    this.setState({ showDisplay: 1 });
    this.getMissedCallsData();
  }
  showCallHistory() {
    this.setState({ showDisplay: 2 });
    this.getMissedCallsData();
  }
  showMissedCalls() {
    this.setState({ showDisplay: 3 });
    this.getMissedCallsData();
  }
  showCallDiversion() {
    this.setState({ showDisplay: 4 });
    this.getMissedCallsData();
  }
  showAddressBook() {
    this.setState({ showDisplay: 5 });
    this.getMissedCallsData();
  }

  render() {
    let tempClass = this.state.showDisplay === 1 ? "col-md-push-8 call-cntrl" : "call-cntrl-no-left";
    let badgeClass = this.state.showDisplay === 3 ? "hide-badge" : "";
    return (
      <div className={"row main-cntnr"}>
        <div className={"btn-cntnr"}>
          <Button className={"other-btn"} onClick={this.showCallCenter.bind(this)}>
            Telefonie
          </Button>
          <Button className={"other-btn"} onClick={this.showCallHistory.bind(this)}>
            Anrufhistorie
          </Button>
          <Badge badgeContent={this.state.numMissedCalls} className={badgeClass} color="error">
            <Button className={"other-btn badge"} onClick={this.showMissedCalls.bind(this)}>
              Verpasste Anrufe
            </Button>
          </Badge>
          <Button className={"other-btn"} onClick={this.showCallDiversion.bind(this)}>
            Rufumleitung
          </Button>
          {this.state.callConnected && (
            <Button className={"other-btn"} onClick={this.showAddressBook.bind(this)}>
              Rufweiterleitung
            </Button>
          )}
          {/* <Button className={"other-btn"} onClick={this.showMissedCalls.bind(this)}>Verpasste Anrufe</Button> */}
        </div>
        {this.state.showDisplay === 1 && (
          <div className={"dialer-div"}>
            <div className={"col-md-4 col-md-push-8 dialpad-head"}>
              <div className={"panel panel-primary client-controls"}>
                <div className={"panel-heading cntrl-heading"}>
                  <h3 className={"panel-title"}>Nummernblock</h3>
                </div>
              </div>
            </div>
            {this.state.showDialpad && (
              <DialpadStyles isConference={false}>
                <DialpadComponent device={this.state.device} />
              </DialpadStyles>
            )}
          </div>
        )}
        {this.state.showDisplay === 2 && (
          <div className={"history-div"}>
            <CallHistory device={this.state.device} />
          </div>
        )}
        {this.state.showDisplay === 3 && (
          <div className={"history-div"}>
            <MissedCalls device={this.state.device} />
          </div>
        )}
        {this.state.showDisplay === 4 && (
          <div className={"history-div"}>
            <CallDiversion device={this.state.device} />
          </div>
        )}
        {this.state.showDisplay === 5 && this.state.callConnected && (
          <div className={"history-div"}>
            <AddressBook device={this.state.device} />
          </div>
        )}
        <div className={`col-md-4 ${tempClass}`}>
          <div className={"panel panel-primary client-controls box-height"}>
            <div className={"panel-heading cntrl-heading"}>
              <h3 className={"panel-title"}>Telefonbedienung</h3>
            </div>
            <div className={"panel-body"}>
              <p>
                <strong>Twilio Status</strong>
              </p>
              <div className={"well well-sm"} id={"call-status"}>
                {this.state.callStatus}
              </div>
              <p>
                <strong>Angezeigte Nummer</strong>
              </p>
              <div className={"well well-sm"} id={"call-status"}>
                {this.props.browser_caller_id}
              </div>

              {this.state.showDTMFInput && <DTMFInput connection={this.state.connection}></DTMFInput>}

              <div className={"btn-controls-div"}>
                {this.state.callIncoming && (
                  <Button className={"answer-button cntrl-btns"} onClick={this.answerCall.bind(this)}>
                    <CallIcon style={{ fontSize: "20px" }}></CallIcon>
                  </Button>
                )}
                {this.state.callIncoming && (
                  <Button className={"hangup-button cntrl-btns"} onClick={this.rejectCall.bind(this)}>
                    <CloseIcon style={{ fontSize: "20px" }}></CloseIcon>
                  </Button>
                )}

                {this.state.callConnected && (
                  <Button className={"cntrl-btns"} onClick={this.toggleMute.bind(this)}>
                    {this.state.callMuted ? <MicOffIcon style={{ fontSize: "20px" }}></MicOffIcon> : <MicIcon style={{ fontSize: "20px" }}></MicIcon>}
                  </Button>
                )}
                {this.state.callConnected && (
                  <Button className={"cntrl-btns"} onClick={this.toggleDTMFInput.bind(this)}>
                    <DialpadIcon style={{ fontSize: "20px" }}></DialpadIcon>
                  </Button>
                )}
                {this.state.callConnected && (
                  <Button className={"hangup-button cntrl-btns"} onClick={this.hangupCall.bind(this)}>
                    <CallEndIcon style={{ fontSize: "20px" }}></CallEndIcon>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CallControl.propTypes = {
  browser_caller_id: PropTypes.string
};
export default CallControl;
