import React from "react";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';

const tableRef = React.createRef();
window.tableRef = tableRef;
const acceptedNumbers = ["1","2","3","4","5","6","7","8","9","0"];
const callHistoryView = {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginTop: '30px',
    padding: '15px',
    overflow: 'auto',
    height: '100%'
};
const callHistoryViewHeading = {
    // fontWeight: 'bold',
    fontSize: '20px',
    marginBottom: '10px',
    marginLeft: '50px'
};
const tableHeading = {
    fontWeight: 400,
    fontSize: '16px',
    marginBottom: '20px',
    marginLeft: '50px',
    marginTop: '30px'
};
const controlStyle = {
    marginLeft: '500px'
}

class CallDiversion extends React.Component{
    constructor(props){
        super();
        this.state = {
            number: '',
            enabled: false
        };
    }
    componentDidMount() {
        this.getBasicData();
    }
    getBasicData(){
        return(new Promise((resolve, reject) => {
            let url = `/get_diversion_data`
            fetch(url)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                let number = result.number;
                if(!number) number = '';
              this.setState({
                enabled: result.enabled,
                number: number
              });
              resolve({
                enabled: result.enabled,
                number: number
              })
            })
        }));
    }
    async sendRequest(number,enabled){
        let finalNum = '';
        if(number.startsWith("+")) finalNum = number;
        else finalNum = "+"+number;
        axios.post('set_diversion_data',{
            enabled: enabled,
            number: finalNum
        });
    }
    saveSetting(){
        let number = "";
        let numberFormatted = "";
        if(this.state.number.startsWith("0")){
            numberFormatted = "49"+this.state.number.substring(1,this.state.number.length);
        }
        else{
            numberFormatted = this.state.number;
        }
        let diversion_enabled = this.state.enabled;
        this.sendRequest(numberFormatted,diversion_enabled);
    }
    toggleButton = (event) =>{
        this.setState({enabled: !this.state.enabled});
        setTimeout(()=>{
            this.saveSetting();
        });
    }
    inputChangeHandler(event){
        let value = event.target.value;
        value = value.replace(/[A-Za-z]/ig, '');
        this.setState({number: value});

    }
    onPaste(event){
        let value = event.clipboardData.getData('Text');
        this.setState({number: value})
    }
    numberVerification(){
        if(this.state.number==="")
            return true;
        if(!this.state.number.startsWith("+"))
            return true;
        if(this.state.number.length<10)
            return true;
        const regEx = /^\+[1-9]\d{10,14}$/;
        return !regEx.test(this.state.number);
    }
    render(){
        let disabled = this.numberVerification();
        return (
            <div style={callHistoryView}>
            <h3 style={callHistoryViewHeading}>Rufumleitung</h3>
            <h6 style={tableHeading}>Hier können Sie eine Rufumleitung einrichten und aktivieren sowie deaktivieren. Bitte fügen Sie hierzu eine Telefonnummer im E.164-Format hinzu (z.B. +49304050400, also mit Ländervorwahl und ohne Sonderzeichen außer dem Pluszeichen zu Beginn der Rufnummer). Sobald die Zielrufnummer eingegeben worden ist, können Sie die Rufumleitung über den Schieberegler aktivieren und deaktivieren. Nach erfolgter Aktivierung sollten Sie die Rufumleitung einmalig testen. Bitte beachten Sie, dass ein Test ausgehend von Ihrem Mobiltelefon fehlschlagen wird, wenn die Zielrufnummer die Ihres eigenen Mobiltelefons ist.</h6>
            <FormControl style={controlStyle}>
                <TextField className='form-control' id="worker-select-input-label" onChange={(event)=>{this.inputChangeHandler(event)}} value={this.state.number} onPaste={(e)=>this.onPaste(e)} label="Zielrufnummer" />
                <FormControlLabel 
                    control = {
                        <Switch
                            checked= {this.state.enabled}
                            // {buddyEnabled}
                            onChange={this.toggleButton}
                            value="diversion_enabled"
                            color="primary"
                            disabled={disabled}
                        />
                    }
                    label="Rufumleitung aktivieren"
                />
            </FormControl>
            </div>
        )
    }
}

export default CallDiversion;