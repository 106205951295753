import React from "react";
import {forwardRef} from "react";
import styled from 'react-emotion';
import MaterialTable from 'material-table';
import moment from 'moment-timezone';
import axios from 'axios';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const tableRef = React.createRef();
window.tableRef = tableRef;

const tableHeading = {
    fontWeight: 400,
      fontSize: '16px',
      marginBottom: '20px',
      marginLeft: '50px',
      marginTop: '30px'
};

const callIcon = {
    cursor: 'pointer',
    marginRight: '5px'
};
const callIconDisabled = {
    cursor: 'pointer',
    marginRight: '5px',
    display: 'none'
}

const callHistoryView = {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginTop: '30px',
    padding: '15px',
    overflow: 'auto',
    height: '100%'
};
const callHistoryViewHeading = {
    // fontWeight: 'bold',
    fontSize: '20px',
    marginBottom: '10px',
    marginLeft: '50px'
};

const MainTableContainer = styled('div')`
  overflow: scroll;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 50px;
  > div:first-child{
    min-width: 1100px;
    box-shadow: none;
    > div:first-child{
        min-height: 0;
    }
  }
  table {
    width: 80%;
  }
  tbody>tr>td{
    color: black;
  }
  tbody>tr>td>div>div>div{
    color: black;
  }
`;

const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
};

function NewlineText(props) {
    const text = props.text;
    const newText = text.split('\n').map(str =>{
        if(str.includes("sip.twilio.com")){
            let r = str.split("@")[0];
            return <p>{r}</p>
        }
        return <p>{str}</p>
    });
    return newText;
}

class AddressBook extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            is_call_enabled: this.props.callEnabled,
            callButtonEnabled: true
        }
    }
    componentDidMount(){
        this.getBasicData();
    }
    async sendRequest(obj){
        await axios.post('/forward_call',{
            call_sid: obj.call_sid,
            number: obj.number,
            type: obj.type,
            direction: obj.direction,
            caller_id: obj.caller_id,
            diversion_enabled: obj.diversion_enabled,
            diversion_number: obj.diversion_number
        });
    }
    smartCallHandling(event){
        this.setState({callButtonEnabled: false});
        let to = event.target.dataset.to;
        let conn = this.props.device._activeConnection;
        let call_sid = conn.parameters.CallSid;
        let direction = conn.direction;
        let diversion_enabled = false;
        let diversion_number = "";
        if(event.target.dataset.div){
            diversion_enabled = event.target.dataset.div;
        }
        if(event.target.dataset.divnum){
            diversion_number = event.target.dataset.divnum;
        }
        let caller_id = "";
        if(direction === "INCOMING"){
            caller_id = conn.parameters.From;
        }
        let type = '';
        if(to.includes('client:')) type = "client";
        else if(to.includes('sip')) type = "sip";
        else type = "group";
        let temp = {
            number: to,
            type: type,
            call_sid: call_sid,
            direction: direction,
            caller_id: caller_id,
            diversion_enabled: diversion_enabled,
            diversion_number: diversion_number
        }
        this.sendRequest(temp);
    }
    getBasicData(){
        let _this = this;
        let columns = [
            {
                title: "Name", field: 'number',
                sorting: false,
                render: rowData => {
                    if(rowData.name){
                        return rowData.name
                    } else if(rowData.number){
                        return rowData.number
                    }
                },
                customFilterAndSearch: (filter,rowData)=>{
                    if(rowData.name) return rowData.name.toLowerCase().includes(filter.toLowerCase())
                    else if(rowData.number) return rowData.number.toLowerCase().includes(filter.toLowerCase())
                }
            },
            {
                title: "Client", field: 'client',
                sorting: false,
                render: rowData => {
                    if(rowData.worker_attributes){
                        return( 
                            <div>{<span role="img" onClick={_this.smartCallHandling.bind(_this)} aria-label="Call" data-to={rowData.worker_attributes.contact_uri} data-div={rowData.diversion_enabled} data-divnum={rowData.diversion_number} style={this.state.callButtonEnabled?callIcon:callIconDisabled}>&#128222;</span>}
                            {rowData.email}</div>
                        );
                    } else if(rowData.sip_id){
                        let rowNum = rowData.sip_id.split('@')[0];
                        return( 
                            <div>{<span role="img" onClick={_this.smartCallHandling.bind(_this)} aria-label="Call" data-to={rowData.sip_id} style={this.state.callButtonEnabled?callIcon:callIconDisabled}>&#128222;</span>}
                            {rowNum}</div>
                        );
                    } else if(rowData.group){
                        let members = rowData.group.split(',').join("\n");
                        return(
                            <div>{<span role="img" onClick={_this.smartCallHandling.bind(_this)} aria-label="Call" data-to={rowData.group} style={this.state.callButtonEnabled?callIcon:callIconDisabled}>&#128222;</span>}
                            <NewlineText text={members} /></div>
                        );
                    }
                },
                customFilterAndSearch: (filter, rowData)=>{
                    if(rowData.worker_attributes) return rowData.email.toLowerCase().includes(filter.toLowerCase())
                    else if (rowData.sip_id) return rowData.sip_id.toLowerCase().includes(filter.toLowerCase())
                    else if (rowData.group) return rowData.group.toLowerCase().includes(filter.toLowerCase())
                }
            }
        ];
        this.setState({agent_columns: columns});
        this.getUsersData();
    }
    getUsersData(query){
        return(new Promise((resolve, reject) => {
            let url = `/get_addressbook_data`
            fetch(url)
            .then(response => response.json())
            .then(result => {
              let new_rows = [];
              for(let i=0;i<result.users.length;i++){
                let elem = result.users[i];
                new_rows.push(elem);
              }
              for(let i=0;i<result.sips.length;i++){
                  let elem = result.sips[i];
                  new_rows.push(elem);
              }
              console.log(new_rows)
              this.setState({
                rows: new_rows
              });
              resolve({
                data: new_rows
              })
            })
        }));
    }

    render(){
        return (
            <div style={callHistoryView}>
                <h3 style={callHistoryViewHeading}>Rufweiterleitung</h3>
                <MainTableContainer>
                    <MaterialTable
                        columns={this.state.agent_columns}
                        tableRef={tableRef}
                        data={this.state.rows}
                        localization={{ body:{emptyDataSourceMessage: <h3>Keine Einträge!</h3>}}}
                        icons={tableIcons}
                        options={{
                            // pageSizeOptions: [],
                            showTitle: false,
                            filtering: true,
                            search: false,
                            // pageSize: 20,
                            // debounceInterval: 700,
                            headerStyle: {
                            backgroundColor: '#184286',
                            color: '#ffffff',
                            zIndex: 1,
                            textAlign: 'center',
                            fontSize: '1em'
                            },
                            rowStyle: rowData => {
                                return({ height: '40px' })
                            },
                            filterCellStyle: {
                                backgroundColor: '#efefef',
                                paddingTop: '2px',
                                paddingBottom: '2px'
                            }
                        }}
                    />
                </MainTableContainer>
            </div>
        );
    }

}

export default AddressBook;