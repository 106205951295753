import React from "react";
import { css } from "emotion";
import Backspace from "@material-ui/icons/Backspace";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Phone from "@material-ui/icons/Phone";

const acceptedNumbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
const screen = css`
  width: 100%;
  height: 60px;
  color: #9e9e9e;
  font-weight: 100;
  padding-top: 10px;
  align-self: center;
  background-color: #2d2d2d !important;
  padding: 6px;
  border-radius: 3px;
`;
const plusButton = css`
  padding: 0 0 !important;
  min-width: 52px !important;
  min-height: 52px;
  background: #2d2d2d !important;
  margin: 15px 15px 0 !important;
  color: white !important;
  border-radius: 38px !important;
  font-size: 18px !important;
  font-weight: bold !important;
`;
const ripple = css`
  background-position: center !important;
  transition: background 0.8s !important;
  &:hover {
    background: #2d2d2d radial-gradient(circle, transparent 1%, #614ffb 1%) center/15000% !important;
    color: black;
  }
  &:active {
    background-color: black !important;
    background-size: 100% !important;
    transition: background 0s !important;
  }
`;
const plus = css`
  text-overflow: ellipsis;
  direction: rtl;
  font-size: 26px !important;
  padding: 11px 14px;
  font-weight: 700;
  letter-spacing: 2px;
  height: 37px;
  width: 55px;
  color: white;
  padding-right: 0;
  margin-top: -20px;
`;
const screenWrapper = css`
  height: 100%;
  align-self: center;
  flex-direction: row;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding-left: 0px;
`;
const screenMainLine = css`
  ${plus};
  overflow: hidden;
  width: 85%;
  direction: ltr;
  height: 100px;
  margin-top: 0;
`;
const phoneNumber = css`
  border: none;
  background-color: transparent;
  outline: none;
  width: 250px;
  direction: ltr;
  color: white;
  padding: 5px 14px;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 2px;
  padding-left: 0px;
`;
const backspaceIcon = css`
  color: #614ffb !important;
  cursor: pointer;
`;
const dialpadKey = css`
  padding: 0 0 !important;
  min-width: 52px !important;
  min-height: 52px;
  background: #2d2d2d !important;
  margin: 15px 15px 0 !important;
  color: white !important;
  border-radius: 38px !important;
  font-size: 18px !important;
  font-weight: bold !important;
`;
const callButton = css`
  background: #614ffb !important;
  padding: 11px;
  border-radius: 30px;
  color: white;
  margin: 15px 0px 15px 0px !important;
  width: 52px;
  height: 52px !important;
  padding: 0px !important;
`;

const phoneIcon = css`
  margin-top: 18px !important;
`;

const functionButton = css`
  margin: "10px";
  padding: "0px";
`;

export default class DialpadComponent extends React.Component {
  numbers = [
    ["1", "2", "3"],
    ["4", "5", "6"],
    ["7", "8", "9"],
    ["*", "0", "#"]
  ];
  constructor(props) {
    super();
    this.state = {
      screenLine: "",
      plus: ""
    };
  }
  dial = () => {
    let number = "";
    let numberFormatted = "";
    if (this.state.screenLine.startsWith("0")) {
      numberFormatted = "49" + this.state.screenLine.substring(1, this.state.screenLine.length);
    } else {
      numberFormatted = this.state.screenLine;
    }
    if (this.state.plus === "") {
      number = "+" + numberFormatted;
    } else {
      number = this.state.plus + numberFormatted;
    }
    this.props.device.connect({
      phoneNumber: number
    });
    this.setState({
      plus: "",
      screenLine: ""
    });
  };
  onKeyDown(event) {
    if (event.key == "Enter") {
      this.dial();
      //dial the number from here
    } else if (event.key == "Backspace") {
      this.setState({ screenLine: this.state.screenLine.substring(0, this.state.screenLine.length - 1) });
    } else {
      if (event.key == "+") {
        if (!this.state.plus.startsWith("+")) this.setState({ plus: "+" });
        // this.setState({screenLine: "+"+this.state.screenLine});
      } else {
        if (acceptedNumbers.includes(event.key)) {
          this.setState({ screenLine: this.state.screenLine + event.key });
        }
      }
    }
  }
  onBackPress() {
    this.setState({ screenLine: this.state.screenLine.substring(0, this.state.screenLine.length - 1) });
  }
  buttonPress(key) {
    if (key == "+") {
      this.setState({ plus: key });
    } else {
      this.setState({ screenLine: this.state.screenLine + key });
    }
  }
  onPaste(event) {
    let value = event.clipboardData.getData("Text");
    if (value.startsWith("+")) {
      this.setState({ plus: value[0], screenLine: value.substring(1, value.length) });
    } else {
      this.setState({ screenLine: value });
    }
  }
  inputChangedHandler(event) {
    //do nothing this is for warning fix
  }
  render() {
    return (
      <div className="dialpad-container">
        <div className={screen}>
          <div className={screenWrapper}>
            <p className={plus}>{this.state.plus}</p>
            <input
              type="text"
              maxLength="15"
              className={`${phoneNumber} input ${screenMainLine}`}
              onChange={(event) => this.inputChangedHandler(event)}
              value={this.state.screenLine}
              onPaste={(e) => this.onPaste(e)}
              onKeyDown={this.onKeyDown.bind(this)}
            />
            <Backspace className={`${backspaceIcon}`} onClick={this.onBackPress.bind(this)} />
          </div>
        </div>
        <div className="buttons-container">
          {this.numbers.map((row, i) => {
            let originalThis = this;

            return (
              <div key={i}>
                {row.map((item, i) => {
                  return (
                    <Button size="large" className={`${ripple} ${dialpadKey}`} key={item} onClick={() => originalThis.buttonPress(item)}>
                      {item}
                    </Button>
                  );
                })}
              </div>
            );
          })}
          <div>
            <Button size="large" key="placeHolder">
              {" "}
            </Button>
            <Button size="large" key="+" className={`${plusButton} ${ripple}`} onClick={() => this.buttonPress("+")}>
              +
            </Button>
            <Button size="large" key="placeHolder2">
              {" "}
            </Button>
          </div>
        </div>
        <IconButton color="inherit" className={functionButton} component="div">
          <div className={callButton}>
            <Phone className={`${functionButton} ${phoneIcon}`} onClick={() => this.dial()} />
          </div>
        </IconButton>
      </div>
    );
  }
}
