import styled from 'react-emotion';

export const DialpadStyles = styled('div')`
  width: fit-content;
  margin-left: 70px;
  margin-top: -70px;
  background-color: #1b1b1d;
  display: flex;
  flex-direction: column;
  text-align:center;
  .buttons-container {
    align-self: center;
  }
  .dialpad-container {
    max-width: 387px;
    margin: auto;
  }
  .directory-select {
    margin: 8px 0px;
  }
  .function-buttons {
    align-self: center;
    justify-content: center;
    padding: 0;
    color: blue;
  }
`

export const ConferenceStyles = styled('div')`
  height: 400px;
  text-align: center;
  .icon-buttons {
    display: contents;
  }
  .icon {
    margin: 20px;
  }
  .inline-block {
    display: inline-block;
  }
`;
